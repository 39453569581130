
import { gsap } from "gsap";
export default {
    data: () => {
        return {
            useCaseSwiper: null,
        }
    },
    mounted() {
        if (process.client) {
            AOS.init({
                duration: 800,
                delay: 200,
                once: false,
                offset: 50,
            });
        }
        if (process.client) {
            gsap.registerPlugin(ScrollTrigger);

            const container = document.querySelector(".cloud-ai-usecase-section");

            const section169 = {
                section: document.querySelector(".cloud-ai-usecase-section"),
                bgColor: "#FFFFFF",
                fontColor: "#000000",
            };

            const section170 = {
                section: document.querySelector(".cloud-ai-blue-card-info-section"),
                bgColor: "#041A4A",
                fontColor: "#FFFFFF",
            };

            function setColors(curr, next) {
                let tl = gsap.timeline({ ease: "power2.inOut" });
                tl.to(container, {
                    duration: 1,
                    backgroundColor: next.bgColor,
                    color: next.fontColor,
                });
            }

            gsap.set(container, {
                backgroundColor: section169.bgColor,
                color: section169.fontColor,
            });

            gsap.to(section170.section, {
                scrollTrigger: {
                    trigger: section170.section,
                    start: "top 55%",
                    end: "top 25%",
                    onEnter: () => setColors(section169, section170),
                    onLeaveBack: () => setColors(section170, section169),
                },
            });
        };
        // const observer = new IntersectionObserver(
        //     ([entry]) => {
        //         if (entry.isIntersecting) {
        //             gsap.fromTo(
        //                 ".useCaseMainContainer",
        //                 { opacity: 0, y: 100, delay: 300 },
        //                 { opacity: 1, y: 0, duration: 1 }
        //             );
        //             observer.disconnect();
        //         }
        //     },
        //     { threshold: 0.5 }
        // );
        // observer.observe(this.$refs.headDesc);
        this.useCaseSwiper = new Swiper(".useCaseSwiper", {
            slidesPerView: 2.5,
            spaceBetween: 30,
            keyboard: {
                enabled: true,
            },
            navigation: {
                nextEl: ".custom-usecase-next-nav",
                prevEl: ".custom-usecase-prev-nav",
            },
            breakpoints: {
                300: {
                    centeredSlides: true,
                    initialSlide: 0,
                    slidesPerView: 1.2,
                    spaceBetween: 15,
                    loop: true,
                },
                768: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                    loop: true,
                },
            },

        });
    }
}
