export const Loader = () => import('../../components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const TataFooter = () => import('../../components/tataFooter.vue' /* webpackChunkName: "components/tata-footer" */).then(c => wrapFunctional(c.default || c))
export const TataFooterFaqs = () => import('../../components/tataFooterFaqs.vue' /* webpackChunkName: "components/tata-footer-faqs" */).then(c => wrapFunctional(c.default || c))
export const TataHeader = () => import('../../components/tataHeader.vue' /* webpackChunkName: "components/tata-header" */).then(c => wrapFunctional(c.default || c))
export const TataHeaderNew = () => import('../../components/tataHeaderNew.vue' /* webpackChunkName: "components/tata-header-new" */).then(c => wrapFunctional(c.default || c))
export const TataKBFooter = () => import('../../components/tataKBFooter.vue' /* webpackChunkName: "components/tata-k-b-footer" */).then(c => wrapFunctional(c.default || c))
export const TataKBHeader = () => import('../../components/tataKBHeader.vue' /* webpackChunkName: "components/tata-k-b-header" */).then(c => wrapFunctional(c.default || c))
export const TataKBHeaderNew = () => import('../../components/tataKBHeaderNew.vue' /* webpackChunkName: "components/tata-k-b-header-new" */).then(c => wrapFunctional(c.default || c))
export const TataNudge = () => import('../../components/tataNudge.vue' /* webpackChunkName: "components/tata-nudge" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeBaseKbArticleCard = () => import('../../components/knowledge-base/kb-article-card.vue' /* webpackChunkName: "components/knowledge-base-kb-article-card" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeBaseKbCategoryList = () => import('../../components/knowledge-base/kb-category-list.vue' /* webpackChunkName: "components/knowledge-base-kb-category-list" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeBaseKbFullCard = () => import('../../components/knowledge-base/kb-full-card.vue' /* webpackChunkName: "components/knowledge-base-kb-full-card" */).then(c => wrapFunctional(c.default || c))
export const KnowledgeBaseKbWidget = () => import('../../components/knowledge-base/kb-widget.vue' /* webpackChunkName: "components/knowledge-base-kb-widget" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsAwardDetails = () => import('../../components/global-components/award-details.vue' /* webpackChunkName: "components/global-components-award-details" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsError404 = () => import('../../components/global-components/error404.vue' /* webpackChunkName: "components/global-components-error404" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsError500 = () => import('../../components/global-components/error500.vue' /* webpackChunkName: "components/global-components-error500" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsMarketo = () => import('../../components/global-components/marketo.vue' /* webpackChunkName: "components/global-components-marketo" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsPage = () => import('../../components/global-components/page.vue' /* webpackChunkName: "components/global-components-page" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataAccordionCard = () => import('../../components/global-components/tata-accordion-card.vue' /* webpackChunkName: "components/global-components-tata-accordion-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataAllianceCard = () => import('../../components/global-components/tata-alliance-card.vue' /* webpackChunkName: "components/global-components-tata-alliance-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataArrowIndicatorNew = () => import('../../components/global-components/tata-arrow-indicator-new.vue' /* webpackChunkName: "components/global-components-tata-arrow-indicator-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataArrowIndicator = () => import('../../components/global-components/tata-arrow-indicator.vue' /* webpackChunkName: "components/global-components-tata-arrow-indicator" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataArrowLink = () => import('../../components/global-components/tata-arrow-link.vue' /* webpackChunkName: "components/global-components-tata-arrow-link" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBannerCta = () => import('../../components/global-components/tata-banner-cta.vue' /* webpackChunkName: "components/global-components-tata-banner-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBannerNew = () => import('../../components/global-components/tata-banner-new.vue' /* webpackChunkName: "components/global-components-tata-banner-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBannerSolutionNew = () => import('../../components/global-components/tata-banner-solution-new.vue' /* webpackChunkName: "components/global-components-tata-banner-solution-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBanner = () => import('../../components/global-components/tata-banner.vue' /* webpackChunkName: "components/global-components-tata-banner" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBlogCard = () => import('../../components/global-components/tata-blog-card.vue' /* webpackChunkName: "components/global-components-tata-blog-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBlueCtaNew = () => import('../../components/global-components/tata-blue-cta-new.vue' /* webpackChunkName: "components/global-components-tata-blue-cta-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBlueCta = () => import('../../components/global-components/tata-blue-cta.vue' /* webpackChunkName: "components/global-components-tata-blue-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBottomCopy = () => import('../../components/global-components/tata-bottom-copy.vue' /* webpackChunkName: "components/global-components-tata-bottom-copy" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataBreadcrum = () => import('../../components/global-components/tata-breadcrum.vue' /* webpackChunkName: "components/global-components-tata-breadcrum" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataClients = () => import('../../components/global-components/tata-clients.vue' /* webpackChunkName: "components/global-components-tata-clients" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataCloudAiCta = () => import('../../components/global-components/tata-cloud-ai-cta.vue' /* webpackChunkName: "components/global-components-tata-cloud-ai-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataContactCard = () => import('../../components/global-components/tata-contact-card.vue' /* webpackChunkName: "components/global-components-tata-contact-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataCookies = () => import('../../components/global-components/tata-cookies.vue' /* webpackChunkName: "components/global-components-tata-cookies" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataCorporateTestimonials = () => import('../../components/global-components/tata-corporate-testimonials.vue' /* webpackChunkName: "components/global-components-tata-corporate-testimonials" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataCustomerSuccess = () => import('../../components/global-components/tata-customer-success.vue' /* webpackChunkName: "components/global-components-tata-customer-success" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataF1Cta = () => import('../../components/global-components/tata-f1-cta.vue' /* webpackChunkName: "components/global-components-tata-f1-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataFaqInnerRepo = () => import('../../components/global-components/tata-faq-inner-repo.vue' /* webpackChunkName: "components/global-components-tata-faq-inner-repo" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataFaqRepo = () => import('../../components/global-components/tata-faq-repo.vue' /* webpackChunkName: "components/global-components-tata-faq-repo" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataFaqSearch = () => import('../../components/global-components/tata-faq-search.vue' /* webpackChunkName: "components/global-components-tata-faq-search" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataGridSec = () => import('../../components/global-components/tata-grid-sec.vue' /* webpackChunkName: "components/global-components-tata-grid-sec" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataHomeVideoCta = () => import('../../components/global-components/tata-home-video-cta.vue' /* webpackChunkName: "components/global-components-tata-home-video-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataImageWrapper = () => import('../../components/global-components/tata-image-wrapper.vue' /* webpackChunkName: "components/global-components-tata-image-wrapper" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataImg = () => import('../../components/global-components/tata-img.vue' /* webpackChunkName: "components/global-components-tata-img" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataIndustryCardHome = () => import('../../components/global-components/tata-industry-card-home.vue' /* webpackChunkName: "components/global-components-tata-industry-card-home" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataIndustryCard = () => import('../../components/global-components/tata-industry-card.vue' /* webpackChunkName: "components/global-components-tata-industry-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataIndustry = () => import('../../components/global-components/tata-industry.vue' /* webpackChunkName: "components/global-components-tata-industry" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataInstaccCard = () => import('../../components/global-components/tata-instacc-card.vue' /* webpackChunkName: "components/global-components-tata-instacc-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataJointCard = () => import('../../components/global-components/tata-joint-card.vue' /* webpackChunkName: "components/global-components-tata-joint-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataLazyloadIframe = () => import('../../components/global-components/tata-lazyload-iframe.vue' /* webpackChunkName: "components/global-components-tata-lazyload-iframe" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataLeftMenu = () => import('../../components/global-components/tata-left-menu.vue' /* webpackChunkName: "components/global-components-tata-left-menu" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataLinkNew = () => import('../../components/global-components/tata-link-new.vue' /* webpackChunkName: "components/global-components-tata-link-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataLink = () => import('../../components/global-components/tata-link.vue' /* webpackChunkName: "components/global-components-tata-link" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataLoader = () => import('../../components/global-components/tata-loader.vue' /* webpackChunkName: "components/global-components-tata-loader" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataLogo = () => import('../../components/global-components/tata-logo.vue' /* webpackChunkName: "components/global-components-tata-logo" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataMobileNavigationNew = () => import('../../components/global-components/tata-mobile-navigation-new.vue' /* webpackChunkName: "components/global-components-tata-mobile-navigation-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataMobileNavigation = () => import('../../components/global-components/tata-mobile-navigation.vue' /* webpackChunkName: "components/global-components-tata-mobile-navigation" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataNewsCenterCard = () => import('../../components/global-components/tata-news-center-card.vue' /* webpackChunkName: "components/global-components-tata-news-center-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataNewsCenterDetails = () => import('../../components/global-components/tata-news-center-details.vue' /* webpackChunkName: "components/global-components-tata-news-center-details" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataNewsCenterLargeCard = () => import('../../components/global-components/tata-news-center-large-card.vue' /* webpackChunkName: "components/global-components-tata-news-center-large-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataNewsCenter = () => import('../../components/global-components/tata-news-center.vue' /* webpackChunkName: "components/global-components-tata-news-center" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataParticles = () => import('../../components/global-components/tata-particles.vue' /* webpackChunkName: "components/global-components-tata-particles" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPopupForm = () => import('../../components/global-components/tata-popup-form.vue' /* webpackChunkName: "components/global-components-tata-popup-form" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPopup = () => import('../../components/global-components/tata-popup.vue' /* webpackChunkName: "components/global-components-tata-popup" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPresentationCard = () => import('../../components/global-components/tata-presentation-card.vue' /* webpackChunkName: "components/global-components-tata-presentation-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPressReleaseCard = () => import('../../components/global-components/tata-press-release-card.vue' /* webpackChunkName: "components/global-components-tata-press-release-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPressRelease = () => import('../../components/global-components/tata-press-release.vue' /* webpackChunkName: "components/global-components-tata-press-release" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPrimaryCard = () => import('../../components/global-components/tata-primary-card.vue' /* webpackChunkName: "components/global-components-tata-primary-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPrimaryCtaNew = () => import('../../components/global-components/tata-primary-cta-new.vue' /* webpackChunkName: "components/global-components-tata-primary-cta-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPrimaryCta = () => import('../../components/global-components/tata-primary-cta.vue' /* webpackChunkName: "components/global-components-tata-primary-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataPrimaryTextCard = () => import('../../components/global-components/tata-primary-text-card.vue' /* webpackChunkName: "components/global-components-tata-primary-text-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataProductCard = () => import('../../components/global-components/tata-product-card.vue' /* webpackChunkName: "components/global-components-tata-product-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataQuotes = () => import('../../components/global-components/tata-quotes.vue' /* webpackChunkName: "components/global-components-tata-quotes" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataRegisterTickerNew = () => import('../../components/global-components/tata-register-ticker-new.vue' /* webpackChunkName: "components/global-components-tata-register-ticker-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataResourceCard = () => import('../../components/global-components/tata-resource-card.vue' /* webpackChunkName: "components/global-components-tata-resource-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataResources = () => import('../../components/global-components/tata-resources.vue' /* webpackChunkName: "components/global-components-tata-resources" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSalesContact = () => import('../../components/global-components/tata-sales-contact.vue' /* webpackChunkName: "components/global-components-tata-sales-contact" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataScrollToTopCta = () => import('../../components/global-components/tata-scroll-to-top-cta.vue' /* webpackChunkName: "components/global-components-tata-scroll-to-top-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataServices = () => import('../../components/global-components/tata-services.vue' /* webpackChunkName: "components/global-components-tata-services" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSlideCard = () => import('../../components/global-components/tata-slide-card.vue' /* webpackChunkName: "components/global-components-tata-slide-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSliderBannerNew = () => import('../../components/global-components/tata-slider-banner-new.vue' /* webpackChunkName: "components/global-components-tata-slider-banner-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSliderBanner = () => import('../../components/global-components/tata-slider-banner.vue' /* webpackChunkName: "components/global-components-tata-slider-banner" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSntResourceCardFull = () => import('../../components/global-components/tata-snt-resource-card-full.vue' /* webpackChunkName: "components/global-components-tata-snt-resource-card-full" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSntResourceCard = () => import('../../components/global-components/tata-snt-resource-card.vue' /* webpackChunkName: "components/global-components-tata-snt-resource-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataSolutionPageCtaNew = () => import('../../components/global-components/tata-solution-page-cta-new.vue' /* webpackChunkName: "components/global-components-tata-solution-page-cta-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataStar = () => import('../../components/global-components/tata-star.vue' /* webpackChunkName: "components/global-components-tata-star" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataStickyNavigation = () => import('../../components/global-components/tata-sticky-navigation.vue' /* webpackChunkName: "components/global-components-tata-sticky-navigation" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataStickyWidgetSolution = () => import('../../components/global-components/tata-sticky-widget-solution.vue' /* webpackChunkName: "components/global-components-tata-sticky-widget-solution" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataTabs = () => import('../../components/global-components/tata-tabs.vue' /* webpackChunkName: "components/global-components-tata-tabs" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataTestimonialCard = () => import('../../components/global-components/tata-testimonial-card.vue' /* webpackChunkName: "components/global-components-tata-testimonial-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataTickerVideoCtaNew = () => import('../../components/global-components/tata-ticker-video-cta-new.vue' /* webpackChunkName: "components/global-components-tata-ticker-video-cta-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataTyperNew = () => import('../../components/global-components/tata-typer-new.vue' /* webpackChunkName: "components/global-components-tata-typer-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataTyper = () => import('../../components/global-components/tata-typer.vue' /* webpackChunkName: "components/global-components-tata-typer" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataVideoCtaNew = () => import('../../components/global-components/tata-video-cta-new.vue' /* webpackChunkName: "components/global-components-tata-video-cta-new" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataVideoCta = () => import('../../components/global-components/tata-video-cta.vue' /* webpackChunkName: "components/global-components-tata-video-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataVideoTab = () => import('../../components/global-components/tata-video-tab.vue' /* webpackChunkName: "components/global-components-tata-video-tab" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataWebinarCard = () => import('../../components/global-components/tata-webinar-card.vue' /* webpackChunkName: "components/global-components-tata-webinar-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataWhiteCta = () => import('../../components/global-components/tata-white-cta.vue' /* webpackChunkName: "components/global-components-tata-white-cta" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsTataYoutubeCard = () => import('../../components/global-components/tata-youtubeCard.vue' /* webpackChunkName: "components/global-components-tata-youtube-card" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection128 = () => import('../../components/global-components/section-components/Section128.vue' /* webpackChunkName: "components/global-components-section128" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSectionComponentsAmpStoriesSolutions = () => import('../../components/global-components/section-components/amp-stories-solutions.vue' /* webpackChunkName: "components/global-components-section-components-amp-stories-solutions" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSectionComponentsAmpStoriesThoughtLeadership = () => import('../../components/global-components/section-components/amp-stories-thought-leadership.vue' /* webpackChunkName: "components/global-components-section-components-amp-stories-thought-leadership" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSectionComponentsAmpStorySectionHome = () => import('../../components/global-components/section-components/amp-story-section-home.vue' /* webpackChunkName: "components/global-components-section-components-amp-story-section-home" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSectionComponentsAmpStorySection = () => import('../../components/global-components/section-components/amp-story-section.vue' /* webpackChunkName: "components/global-components-section-components-amp-story-section" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection1 = () => import('../../components/global-components/section-components/section1.vue' /* webpackChunkName: "components/global-components-section1" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection10 = () => import('../../components/global-components/section-components/section10.vue' /* webpackChunkName: "components/global-components-section10" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection100 = () => import('../../components/global-components/section-components/section100.vue' /* webpackChunkName: "components/global-components-section100" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection101 = () => import('../../components/global-components/section-components/section101.vue' /* webpackChunkName: "components/global-components-section101" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection102 = () => import('../../components/global-components/section-components/section102.vue' /* webpackChunkName: "components/global-components-section102" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection103 = () => import('../../components/global-components/section-components/section103.vue' /* webpackChunkName: "components/global-components-section103" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection104 = () => import('../../components/global-components/section-components/section104.vue' /* webpackChunkName: "components/global-components-section104" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection105 = () => import('../../components/global-components/section-components/section105.vue' /* webpackChunkName: "components/global-components-section105" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection106 = () => import('../../components/global-components/section-components/section106.vue' /* webpackChunkName: "components/global-components-section106" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection107 = () => import('../../components/global-components/section-components/section107.vue' /* webpackChunkName: "components/global-components-section107" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection108 = () => import('../../components/global-components/section-components/section108.vue' /* webpackChunkName: "components/global-components-section108" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection109 = () => import('../../components/global-components/section-components/section109.vue' /* webpackChunkName: "components/global-components-section109" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection11 = () => import('../../components/global-components/section-components/section11.vue' /* webpackChunkName: "components/global-components-section11" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection110 = () => import('../../components/global-components/section-components/section110.vue' /* webpackChunkName: "components/global-components-section110" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection111 = () => import('../../components/global-components/section-components/section111.vue' /* webpackChunkName: "components/global-components-section111" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection112 = () => import('../../components/global-components/section-components/section112.vue' /* webpackChunkName: "components/global-components-section112" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection113 = () => import('../../components/global-components/section-components/section113.vue' /* webpackChunkName: "components/global-components-section113" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection114 = () => import('../../components/global-components/section-components/section114.vue' /* webpackChunkName: "components/global-components-section114" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection115 = () => import('../../components/global-components/section-components/section115.vue' /* webpackChunkName: "components/global-components-section115" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection116 = () => import('../../components/global-components/section-components/section116.vue' /* webpackChunkName: "components/global-components-section116" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection117 = () => import('../../components/global-components/section-components/section117.vue' /* webpackChunkName: "components/global-components-section117" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection118 = () => import('../../components/global-components/section-components/section118.vue' /* webpackChunkName: "components/global-components-section118" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection119 = () => import('../../components/global-components/section-components/section119.vue' /* webpackChunkName: "components/global-components-section119" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection12 = () => import('../../components/global-components/section-components/section12.vue' /* webpackChunkName: "components/global-components-section12" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection120 = () => import('../../components/global-components/section-components/section120.vue' /* webpackChunkName: "components/global-components-section120" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection123 = () => import('../../components/global-components/section-components/section123.vue' /* webpackChunkName: "components/global-components-section123" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection124 = () => import('../../components/global-components/section-components/section124.vue' /* webpackChunkName: "components/global-components-section124" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection125 = () => import('../../components/global-components/section-components/section125.vue' /* webpackChunkName: "components/global-components-section125" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection126 = () => import('../../components/global-components/section-components/section126.vue' /* webpackChunkName: "components/global-components-section126" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection129 = () => import('../../components/global-components/section-components/section129.vue' /* webpackChunkName: "components/global-components-section129" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection13 = () => import('../../components/global-components/section-components/section13.vue' /* webpackChunkName: "components/global-components-section13" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection130 = () => import('../../components/global-components/section-components/section130.vue' /* webpackChunkName: "components/global-components-section130" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection131 = () => import('../../components/global-components/section-components/section131.vue' /* webpackChunkName: "components/global-components-section131" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection132 = () => import('../../components/global-components/section-components/section132.vue' /* webpackChunkName: "components/global-components-section132" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection133 = () => import('../../components/global-components/section-components/section133.vue' /* webpackChunkName: "components/global-components-section133" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection134 = () => import('../../components/global-components/section-components/section134.vue' /* webpackChunkName: "components/global-components-section134" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection135 = () => import('../../components/global-components/section-components/section135.vue' /* webpackChunkName: "components/global-components-section135" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection136 = () => import('../../components/global-components/section-components/section136.vue' /* webpackChunkName: "components/global-components-section136" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection137 = () => import('../../components/global-components/section-components/section137.vue' /* webpackChunkName: "components/global-components-section137" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection138 = () => import('../../components/global-components/section-components/section138.vue' /* webpackChunkName: "components/global-components-section138" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection139 = () => import('../../components/global-components/section-components/section139.vue' /* webpackChunkName: "components/global-components-section139" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection13rev = () => import('../../components/global-components/section-components/section13rev.vue' /* webpackChunkName: "components/global-components-section13rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection14 = () => import('../../components/global-components/section-components/section14.vue' /* webpackChunkName: "components/global-components-section14" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection140 = () => import('../../components/global-components/section-components/section140.vue' /* webpackChunkName: "components/global-components-section140" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection141 = () => import('../../components/global-components/section-components/section141.vue' /* webpackChunkName: "components/global-components-section141" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection142 = () => import('../../components/global-components/section-components/section142.vue' /* webpackChunkName: "components/global-components-section142" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection143 = () => import('../../components/global-components/section-components/section143.vue' /* webpackChunkName: "components/global-components-section143" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection144 = () => import('../../components/global-components/section-components/section144.vue' /* webpackChunkName: "components/global-components-section144" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection145 = () => import('../../components/global-components/section-components/section145.vue' /* webpackChunkName: "components/global-components-section145" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection146 = () => import('../../components/global-components/section-components/section146.vue' /* webpackChunkName: "components/global-components-section146" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection148 = () => import('../../components/global-components/section-components/section148.vue' /* webpackChunkName: "components/global-components-section148" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection149 = () => import('../../components/global-components/section-components/section149.vue' /* webpackChunkName: "components/global-components-section149" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection15 = () => import('../../components/global-components/section-components/section15.vue' /* webpackChunkName: "components/global-components-section15" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection151 = () => import('../../components/global-components/section-components/section151.vue' /* webpackChunkName: "components/global-components-section151" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection152 = () => import('../../components/global-components/section-components/section152.vue' /* webpackChunkName: "components/global-components-section152" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection153 = () => import('../../components/global-components/section-components/section153.vue' /* webpackChunkName: "components/global-components-section153" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection154 = () => import('../../components/global-components/section-components/section154.vue' /* webpackChunkName: "components/global-components-section154" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection156 = () => import('../../components/global-components/section-components/section156.vue' /* webpackChunkName: "components/global-components-section156" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection16 = () => import('../../components/global-components/section-components/section16.vue' /* webpackChunkName: "components/global-components-section16" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection163 = () => import('../../components/global-components/section-components/section163.vue' /* webpackChunkName: "components/global-components-section163" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection164 = () => import('../../components/global-components/section-components/section164.vue' /* webpackChunkName: "components/global-components-section164" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection165 = () => import('../../components/global-components/section-components/section165.vue' /* webpackChunkName: "components/global-components-section165" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection166 = () => import('../../components/global-components/section-components/section166.vue' /* webpackChunkName: "components/global-components-section166" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection167 = () => import('../../components/global-components/section-components/section167.vue' /* webpackChunkName: "components/global-components-section167" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection168 = () => import('../../components/global-components/section-components/section168.vue' /* webpackChunkName: "components/global-components-section168" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection169 = () => import('../../components/global-components/section-components/section169.vue' /* webpackChunkName: "components/global-components-section169" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection17 = () => import('../../components/global-components/section-components/section17.vue' /* webpackChunkName: "components/global-components-section17" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection170 = () => import('../../components/global-components/section-components/section170.vue' /* webpackChunkName: "components/global-components-section170" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection172 = () => import('../../components/global-components/section-components/section172.vue' /* webpackChunkName: "components/global-components-section172" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection18 = () => import('../../components/global-components/section-components/section18.vue' /* webpackChunkName: "components/global-components-section18" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection19 = () => import('../../components/global-components/section-components/section19.vue' /* webpackChunkName: "components/global-components-section19" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection2 = () => import('../../components/global-components/section-components/section2.vue' /* webpackChunkName: "components/global-components-section2" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection20 = () => import('../../components/global-components/section-components/section20.vue' /* webpackChunkName: "components/global-components-section20" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection21 = () => import('../../components/global-components/section-components/section21.vue' /* webpackChunkName: "components/global-components-section21" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection22 = () => import('../../components/global-components/section-components/section22.vue' /* webpackChunkName: "components/global-components-section22" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection23 = () => import('../../components/global-components/section-components/section23.vue' /* webpackChunkName: "components/global-components-section23" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection24 = () => import('../../components/global-components/section-components/section24.vue' /* webpackChunkName: "components/global-components-section24" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection25 = () => import('../../components/global-components/section-components/section25.vue' /* webpackChunkName: "components/global-components-section25" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection26 = () => import('../../components/global-components/section-components/section26.vue' /* webpackChunkName: "components/global-components-section26" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection27 = () => import('../../components/global-components/section-components/section27.vue' /* webpackChunkName: "components/global-components-section27" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection28 = () => import('../../components/global-components/section-components/section28.vue' /* webpackChunkName: "components/global-components-section28" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection2rev = () => import('../../components/global-components/section-components/section2rev.vue' /* webpackChunkName: "components/global-components-section2rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection3 = () => import('../../components/global-components/section-components/section3.vue' /* webpackChunkName: "components/global-components-section3" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection30 = () => import('../../components/global-components/section-components/section30.vue' /* webpackChunkName: "components/global-components-section30" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection31 = () => import('../../components/global-components/section-components/section31.vue' /* webpackChunkName: "components/global-components-section31" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection32 = () => import('../../components/global-components/section-components/section32.vue' /* webpackChunkName: "components/global-components-section32" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection33 = () => import('../../components/global-components/section-components/section33.vue' /* webpackChunkName: "components/global-components-section33" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection34 = () => import('../../components/global-components/section-components/section34.vue' /* webpackChunkName: "components/global-components-section34" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection35 = () => import('../../components/global-components/section-components/section35.vue' /* webpackChunkName: "components/global-components-section35" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection36 = () => import('../../components/global-components/section-components/section36.vue' /* webpackChunkName: "components/global-components-section36" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection37 = () => import('../../components/global-components/section-components/section37.vue' /* webpackChunkName: "components/global-components-section37" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection38 = () => import('../../components/global-components/section-components/section38.vue' /* webpackChunkName: "components/global-components-section38" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection39 = () => import('../../components/global-components/section-components/section39.vue' /* webpackChunkName: "components/global-components-section39" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection39rev = () => import('../../components/global-components/section-components/section39rev.vue' /* webpackChunkName: "components/global-components-section39rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection4 = () => import('../../components/global-components/section-components/section4.vue' /* webpackChunkName: "components/global-components-section4" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection40 = () => import('../../components/global-components/section-components/section40.vue' /* webpackChunkName: "components/global-components-section40" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection40rev = () => import('../../components/global-components/section-components/section40rev.vue' /* webpackChunkName: "components/global-components-section40rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection41 = () => import('../../components/global-components/section-components/section41.vue' /* webpackChunkName: "components/global-components-section41" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection41rev = () => import('../../components/global-components/section-components/section41rev.vue' /* webpackChunkName: "components/global-components-section41rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection42 = () => import('../../components/global-components/section-components/section42.vue' /* webpackChunkName: "components/global-components-section42" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection42rev = () => import('../../components/global-components/section-components/section42rev.vue' /* webpackChunkName: "components/global-components-section42rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection43 = () => import('../../components/global-components/section-components/section43.vue' /* webpackChunkName: "components/global-components-section43" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection44 = () => import('../../components/global-components/section-components/section44.vue' /* webpackChunkName: "components/global-components-section44" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection44rev = () => import('../../components/global-components/section-components/section44rev.vue' /* webpackChunkName: "components/global-components-section44rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection45 = () => import('../../components/global-components/section-components/section45.vue' /* webpackChunkName: "components/global-components-section45" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection46 = () => import('../../components/global-components/section-components/section46.vue' /* webpackChunkName: "components/global-components-section46" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection47 = () => import('../../components/global-components/section-components/section47.vue' /* webpackChunkName: "components/global-components-section47" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection48 = () => import('../../components/global-components/section-components/section48.vue' /* webpackChunkName: "components/global-components-section48" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection49 = () => import('../../components/global-components/section-components/section49.vue' /* webpackChunkName: "components/global-components-section49" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection5 = () => import('../../components/global-components/section-components/section5.vue' /* webpackChunkName: "components/global-components-section5" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection50 = () => import('../../components/global-components/section-components/section50.vue' /* webpackChunkName: "components/global-components-section50" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection51 = () => import('../../components/global-components/section-components/section51.vue' /* webpackChunkName: "components/global-components-section51" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection52 = () => import('../../components/global-components/section-components/section52.vue' /* webpackChunkName: "components/global-components-section52" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection53 = () => import('../../components/global-components/section-components/section53.vue' /* webpackChunkName: "components/global-components-section53" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection54 = () => import('../../components/global-components/section-components/section54.vue' /* webpackChunkName: "components/global-components-section54" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection55 = () => import('../../components/global-components/section-components/section55.vue' /* webpackChunkName: "components/global-components-section55" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection56 = () => import('../../components/global-components/section-components/section56.vue' /* webpackChunkName: "components/global-components-section56" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection57 = () => import('../../components/global-components/section-components/section57.vue' /* webpackChunkName: "components/global-components-section57" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection58 = () => import('../../components/global-components/section-components/section58.vue' /* webpackChunkName: "components/global-components-section58" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection59 = () => import('../../components/global-components/section-components/section59.vue' /* webpackChunkName: "components/global-components-section59" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection6 = () => import('../../components/global-components/section-components/section6.vue' /* webpackChunkName: "components/global-components-section6" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection60 = () => import('../../components/global-components/section-components/section60.vue' /* webpackChunkName: "components/global-components-section60" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection61 = () => import('../../components/global-components/section-components/section61.vue' /* webpackChunkName: "components/global-components-section61" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection62 = () => import('../../components/global-components/section-components/section62.vue' /* webpackChunkName: "components/global-components-section62" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection63 = () => import('../../components/global-components/section-components/section63.vue' /* webpackChunkName: "components/global-components-section63" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection64 = () => import('../../components/global-components/section-components/section64.vue' /* webpackChunkName: "components/global-components-section64" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection65 = () => import('../../components/global-components/section-components/section65.vue' /* webpackChunkName: "components/global-components-section65" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection66 = () => import('../../components/global-components/section-components/section66.vue' /* webpackChunkName: "components/global-components-section66" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection67 = () => import('../../components/global-components/section-components/section67.vue' /* webpackChunkName: "components/global-components-section67" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection68 = () => import('../../components/global-components/section-components/section68.vue' /* webpackChunkName: "components/global-components-section68" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection69 = () => import('../../components/global-components/section-components/section69.vue' /* webpackChunkName: "components/global-components-section69" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection6rev = () => import('../../components/global-components/section-components/section6rev.vue' /* webpackChunkName: "components/global-components-section6rev" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection7 = () => import('../../components/global-components/section-components/section7.vue' /* webpackChunkName: "components/global-components-section7" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection70 = () => import('../../components/global-components/section-components/section70.vue' /* webpackChunkName: "components/global-components-section70" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection71 = () => import('../../components/global-components/section-components/section71.vue' /* webpackChunkName: "components/global-components-section71" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection72 = () => import('../../components/global-components/section-components/section72.vue' /* webpackChunkName: "components/global-components-section72" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection73 = () => import('../../components/global-components/section-components/section73.vue' /* webpackChunkName: "components/global-components-section73" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection74 = () => import('../../components/global-components/section-components/section74.vue' /* webpackChunkName: "components/global-components-section74" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection75 = () => import('../../components/global-components/section-components/section75.vue' /* webpackChunkName: "components/global-components-section75" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection76 = () => import('../../components/global-components/section-components/section76.vue' /* webpackChunkName: "components/global-components-section76" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection77 = () => import('../../components/global-components/section-components/section77.vue' /* webpackChunkName: "components/global-components-section77" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection78 = () => import('../../components/global-components/section-components/section78.vue' /* webpackChunkName: "components/global-components-section78" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection79 = () => import('../../components/global-components/section-components/section79.vue' /* webpackChunkName: "components/global-components-section79" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection8 = () => import('../../components/global-components/section-components/section8.vue' /* webpackChunkName: "components/global-components-section8" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection80 = () => import('../../components/global-components/section-components/section80.vue' /* webpackChunkName: "components/global-components-section80" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection81 = () => import('../../components/global-components/section-components/section81.vue' /* webpackChunkName: "components/global-components-section81" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection82 = () => import('../../components/global-components/section-components/section82.vue' /* webpackChunkName: "components/global-components-section82" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection83 = () => import('../../components/global-components/section-components/section83.vue' /* webpackChunkName: "components/global-components-section83" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection84 = () => import('../../components/global-components/section-components/section84.vue' /* webpackChunkName: "components/global-components-section84" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection85 = () => import('../../components/global-components/section-components/section85.vue' /* webpackChunkName: "components/global-components-section85" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection88 = () => import('../../components/global-components/section-components/section88.vue' /* webpackChunkName: "components/global-components-section88" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection89 = () => import('../../components/global-components/section-components/section89.vue' /* webpackChunkName: "components/global-components-section89" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection9 = () => import('../../components/global-components/section-components/section9.vue' /* webpackChunkName: "components/global-components-section9" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection90 = () => import('../../components/global-components/section-components/section90.vue' /* webpackChunkName: "components/global-components-section90" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection91 = () => import('../../components/global-components/section-components/section91.vue' /* webpackChunkName: "components/global-components-section91" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection92 = () => import('../../components/global-components/section-components/section92.vue' /* webpackChunkName: "components/global-components-section92" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection93 = () => import('../../components/global-components/section-components/section93.vue' /* webpackChunkName: "components/global-components-section93" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection95 = () => import('../../components/global-components/section-components/section95.vue' /* webpackChunkName: "components/global-components-section95" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection96 = () => import('../../components/global-components/section-components/section96.vue' /* webpackChunkName: "components/global-components-section96" */).then(c => wrapFunctional(c.default || c))
export const GlobalComponentsSection99 = () => import('../../components/global-components/section-components/section99.vue' /* webpackChunkName: "components/global-components-section99" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
