
export default {
    data() {
        return {
            isMobile: false, // Default to false; updated in mounted
        };
    },
    computed: {
        backgroundImage() {
            return this.isMobile && this.$attrs.section_bg_mobile
                ? this.$attrs.section_bg_mobile.url
                : this.$attrs.section_bg_image.url;
        },
    },
    mounted() {
        if (typeof window !== "undefined") {
            this.isMobile = window.innerWidth <= 768; // Initialize `isMobile` on mount
            window.addEventListener("resize", this.updateIsMobile);
        }
        gsap.from('.banner-content', {
            opacity: 0,
            duration: 1,
            y: 50,
            delay: 0.6,
        });
    },
    beforeUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.updateIsMobile);
        }
    },
    methods: {
        updateIsMobile() {
            const wasMobile = this.isMobile;
            this.isMobile = window.innerWidth <= 768;
            if (wasMobile !== this.isMobile) {
                // The `backgroundImage` will automatically update because it’s a computed property
            }
        },
    },
}
