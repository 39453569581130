
export default {
    data() {
        return {
            ctaBorderColor: 'white-bg-bolor'
        };
    },
    mounted() {
        if (process.client) {
            AOS.init({
                duration: 1000,
                once: true,
                offset: 20,
            });

            gsap.registerPlugin(ScrollTrigger);

            const container = document.querySelector(".cloud-ai-image-content-section");
            const borderColor = document.querySelector(".white-bg-bolor");

            const scrolledBgColor = 'linear-gradient(#ffffff, #ffffff) padding-box, conic-gradient(from var(--angle), #34425E, #00A4FF) border-box';
            const initialBgColor = 'linear-gradient(#061c4e, #061c4e) padding-box, conic-gradient(from var(--angle), #34425E, #00A4FF) border-box';


            const section164 = {
                section: document.querySelector(".cloud-ai-image-content-section"),
                bgColor: "#041A4A",
                fontColor: "#FFFFFF",
            };

            const section165 = {
                section: document.querySelector(".new-cloud-ai-card-section"),
                bgColor: "#FFFFFF",
                fontColor: "#000000",
            };


            function setBorderColor(nextStyle) {
                gsap.to(borderColor, {
                    duration: 1,
                    background: nextStyle,
                });
            }

            function setColors(curr, next) {
                let tl = gsap.timeline({ ease: "power2.inOut" });
                tl.to(container, {
                    duration: 1,
                    backgroundColor: next.bgColor,
                    color: next.fontColor,
                });
            }

            gsap.set(container, {
                backgroundColor: section164.bgColor,
                color: section164.fontColor,
            });

            gsap.to(section165.section, {
                scrollTrigger: {
                    trigger: section165.section,
                    start: "top 55%",
                    end: "top 25%",
                    onEnter: () => { setColors(section164, section165); setBorderColor(scrolledBgColor); },
                    onLeaveBack: () => { setColors(section165, section164); setBorderColor(initialBgColor); },
                },
            });
        };
        gsap.from(".image-container", {
            scrollTrigger: {
                trigger: ".cloud-ai-image-content-section",
                start: "top 60%",
                end: "top 40%",
                scrub: 3,
            },
            x: 100,
            opacity: 0,
            delay: 10,
            duration: 3,
            ease: "sine.out",
        });

    },
}
